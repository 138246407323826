export const AreaOrder = {
  us: 'United States',
  europe: 'Europe',
  na: 'North America',
  anz: 'Australia and New Zealand',
  asia: 'Asia',
  sa: 'South America',
  china: 'China',
  india: 'India',
  sea: 'South East Asia',
  af: 'Africa',
  me: 'Middle East',
  unknown: 'Others',
};
