import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { Cluster } from '../../../../../generated/graphql';
import Pusher from 'pusher-js';
import ClusterAppsList from './List';
import ClusterApp from './App';

const ClusterApps: React.FunctionComponent<{
  cluster: Cluster;
  pusher: Pusher.Pusher;
  channel: Pusher.Channel;
}> = props => {
  return (
    <Routes>
      <Route index element={<ClusterAppsList {...props} />} />
      <Route path=":name/*" element={<ClusterApp {...props} />} />
    </Routes>
  );
};

export default ClusterApps;
